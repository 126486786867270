import { Link, useLocation } from 'react-router-dom';
import { LNBPropsType } from '../../../../types/LNBPropsType';
import { useEffect, useState } from 'react';
import tabStore from '../../../../stores/tabStore';

const policyPath = '/sellerOffice/managePolicy';

export default function LNBPolicy(props: LNBPropsType) {
  const { menuOpen } = props;

  const location = useLocation();

  const [menuActive, setMenuActive] = useState<boolean>(false);
  const [depActive, setDepActive] = useState<boolean[]>([false, false]);

  const handleClickMenu = () => setMenuActive(!menuActive);

  const { items: tabItems, addItem, activeItemByUrl } = tabStore();

  const handleTabList = (e) => {
    const targetPath = e.view.location.pathname;

    const existItemIndex = tabItems.findIndex((item) => item.linkUrl === targetPath);
    if (existItemIndex === -1) {
      addItem({
        linkUrl: e.view.location.pathname,
        name: e.target.innerText,
        active: true,
      });
    }

    activeItemByUrl(e.view.location.pathname);
  };

  useEffect(() => {
    const dep = [false];

    setMenuActive(location.pathname.includes(policyPath) ? true : false);
    switch (true) {
      case [`${policyPath}/basic`, `${policyPath}/template`].includes(location.pathname):
        dep[0] = true;
        break;
    }

    setDepActive(dep);
  }, [setMenuActive, location]);

  return (
    <li className={menuActive ? 'open' : ''}>
      <div className="folding-head">
        <div onClick={handleClickMenu} className="folding-btn">
          <i className="ico-policy"></i>
          {menuOpen ? '정책생성·적용관리' : '정책·적용'}
        </div>
      </div>
      <div className="folding-panel">
        <ul>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to={'managePolicy/basic'}>기본 정책 관리</Link>
          </li>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to={'managePolicy/template'}>템플릿 관리</Link>
          </li>
        </ul>
      </div>
    </li>
  );
}
