import { Box, Button } from '@mui/joy';

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import instance from '../../../../apis/Request';
import { popupStore } from '../../../../stores/popupStore';
import solutionStore from '../../../../stores/solutionStore';
import { useLocation } from 'react-router-dom';
import RemindSolution from './RemindSolution';
import { errorBar, successBar, warningBar } from '../../../Util/SnackBar/SnackBar';
import { TossRequestPaymentResult } from '../../../../types/template';
import userStore from '../../../../stores/userStore';
import { alertStore } from '../../../../stores/alertStore';

import TossPaymentWidget from '../../../Util/Payment/TossPaymentWidget';
import useTossPaymentWidget from '../../../Util/Payment/hooks/useTossPaymentWidget';
import Loading from '../../../Util/Loading/Loading';

export default function ChangeSolution({ solutionNo }) {
  const location = useLocation();
  const { closePopup } = popupStore();
  const { solutionList, chargeDays, userSolution, userPreviousSolution, refreshUserSolution } = solutionStore();
  const { refreshUserInfo } = userStore();
  const { openAlert } = alertStore();
  const { openPopup } = popupStore();

  const latestSolution = userSolution || userPreviousSolution;
  const [chargeSolutionInfo, setChargeSolutionInfo] = useState({
    soltProductNo: !!solutionNo ? solutionNo : latestSolution?.solutionProductNo,
    // 사용중인 솔루션 기간의 코드로 변경
    solutionUseDayCode: latestSolution.periodCode || '01',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [startPayment, setStartPayment] = useState<boolean>(false);
  const [changeSolutionData, setChangeSolutionData] = useState<any>(null);

  const remainDate = dayjs(latestSolution.useEndDate).diff(dayjs(), 'days');

  const tossWidget = useTossPaymentWidget({
    initialPrice: 0,
  });

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let val = name === 'solutionUseDayCode' ? value : parseInt(value);

    setChargeSolutionInfo((prev) => {
      return {
        ...prev,
        [name]: val,
      };
    });
    fetchChangeSolution({ ...chargeSolutionInfo, [name]: val });
  };

  const fetchChangeSolution = async (params) => {
    setLoading(true);
    try {
      const response = await instance.get('/solution/change', {
        params,
      });

      if (response.data.responseCode === '10030') {
        openAlert('연장 이력이 존재합니다.\n업그레이드가 불가합니다.', '확인');
        closePopup();
        return;
      }
      console.log('handleRadioChange fetchChangeSolution', response.data);
      setChangeSolutionData(response.data.responseData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    if (location.pathname === '/') {
      closePopup();
      openPopup('center', '솔루션 만료 안내', <RemindSolution />);
    } else {
      closePopup();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loading || startPayment) return;

    try {
      setLoading(true);
      setStartPayment(true);
      // 솔루션 구매신청(결재 요청)
      const { data: payReqData } = await instance.post('/solution/request', {
        soltProductNo: chargeSolutionInfo.soltProductNo,
        useDayCode: chargeSolutionInfo.solutionUseDayCode,
        buyType: changeSolutionData.buyType, //
      });

      if (parseInt(payReqData.responseCode) !== 10000) {
        warningBar(payReqData.responseMessage);
        setLoading(false);
        setStartPayment(false);
        return;
      }

      //   response example

      console.log('payReqData', payReqData);

      // TODO : 토스페이 연동
      const tossPaymentReqBody = {
        orderId: payReqData.responseData.orderId,
        orderName: '마켓위즈 솔루션 변경',
        customerName: payReqData.responseData.slrName,
        customerEmail: payReqData.responseData.slrEmail,
        // customerMobilePhone: '01011112222', (필수여부 확인 필요)
      };

      setLoading(false);

      // 결재금액 설정
      tossWidget.handlePrice(payReqData.responseData.priceAmt);

      tossWidget.handlePaymentWithCallBack(tossPaymentReqBody, confirmPayment(payReqData.priceNo));
    } catch (error) {
      console.error(error);
      errorBar('나중에 다시 시도하세요.');
      setStartPayment(false);
    } finally {
      setLoading(false);
    }
  };

  // 결제 및 구매 (결제완료)
  const confirmPayment = (priceNo: string) => async (paymentResult: TossRequestPaymentResult) => {
    const { orderId, paymentKey, amount } = paymentResult;
    if (loading) return;

    try {
      setLoading(true);
      const { data: confirmReqData } = await instance.post('/solution/confirm', {
        priceNo,
        priceAmt: amount,
        paymentKey: paymentKey, //  토스 결제 response
        orderId: orderId, //  토스 결제 response
        buyType: changeSolutionData.buyType,
      });
      console.log('payReqData', confirmReqData);

      await refreshUserInfo();
      await refreshUserSolution();

      successBar('솔루션 변경 신청이 완료되었습니다.');

      closePopup();
    } catch (error) {
      console.error(error);
      errorBar('나중에 다시 시도하세요.');
    } finally {
      setLoading(false);
      setStartPayment(false);
    }
  };

  useEffect(() => {
    fetchChangeSolution({ ...chargeSolutionInfo });
  }, []);

  return (
    <form onSubmit={handleSubmit} className="table mt-16">
      <table className="mt-10">
        <colgroup>
          <col style={{ width: '22rem' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: '22rem' }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={3} className="bd-line-2">
              <p className="sub-txt t-center fw-smbold">솔루션 이용현황</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="t-center">솔루션 종류</th>
            <th className="t-center">이용기간</th>
            <th className="t-center">기간 / 요금</th>
          </tr>
          <tr>
            <td className="t-center">{latestSolution.solutionProductName}</td>
            <td className="t-center">
              {latestSolution?.useStartDate} ~ {latestSolution?.useEndDate}{' '}
              <em className="fc-red">
                ({remainDate > 0 ? <>{remainDate - 1}일 남음</> : <>{Math.abs(remainDate)}일 지남</>})
              </em>
            </td>
            <td className="t-center">
              {latestSolution?.period}일 / {latestSolution?.solutionProductAmount}원
            </td>
          </tr>
        </tbody>
      </table>

      <table className="mt-10">
        <colgroup>
          <col style={{ width: '22rem' }} />
          <col style={{ width: '22rem' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: '22rem' }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={4} className="bd-line-2">
              <p className="sub-txt t-center fw-smbold">솔루션 선택</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="t-center">솔루션명</th>
            <th className="t-center">사용기간</th>
            <th className="t-center">제공사항</th>
            <th className="t-center">월 이용료</th>
          </tr>
          {solutionList.map((solution, idx) => {
            return (
              <tr key={idx}>
                <td className="t-center">
                  <div className="radio-box">
                    <input
                      type="radio"
                      className="radio"
                      id={solution.solutionName}
                      name="soltProductNo"
                      checked={chargeSolutionInfo.soltProductNo === solution.sollutionNo}
                      value={solution.sollutionNo}
                      disabled={loading}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor={solution.solutionName}>{solution.solutionName}</label>
                    {latestSolution.solutionProductName === solution.solutionName && (
                      <span className="badge large blue ml-6">이용중</span>
                    )}
                  </div>
                </td>
                <td className="t-center">
                  <div className="radio-box">
                    <input
                      type="radio"
                      className="radio"
                      disabled={loading}
                      id={chargeDays[idx].dayName}
                      name="solutionUseDayCode"
                      value={chargeDays[idx].dayCode}
                      checked={chargeDays[idx].dayCode === chargeSolutionInfo.solutionUseDayCode}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor={chargeDays[idx].dayName}>{chargeDays[idx].dayName}</label>
                    {latestSolution.solutionProductName === solution.solutionName && (
                      <span className="badge large blue ml-6">이용중</span>
                    )}
                  </div>
                  {/* )} */}
                </td>
                <td className="t-center">
                  ㆍ업로드마켓 계정 수 : <span className="fw-smbold">{solution.uploadAccountCount}개</span>
                  <br />
                  ㆍ상품 저장 수 : <span className="fw-smbold">{solution.productionSaveCount}개</span>
                </td>
                <td className="t-center">{solution.solutionPrice.toLocaleString('ko-KR')}원</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {changeSolutionData === null ? (
        <Loading />
      ) : (
        <table className="mt-10">
          <colgroup>
            <col style={{ width: '22rem' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={3} className="bd-line-2">
                <p className="sub-txt t-center fw-smbold">
                  결제 금액
                  <br />
                  <span className="fz-13 fw-regular">(결제 금액은 잔여일만큼 일할 계산되어 결제됩니다.)</span>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="t-center">기존 결제액 (VAT 포함)</th>
              <td className="t-right">{changeSolutionData?.existingPayment}원</td>
            </tr>
            <tr>
              <th className="t-center">사용 금액 (취소 수수료 포함)</th>
              <td className="t-right">{changeSolutionData?.usedAmt}원</td>
            </tr>
            <tr>
              <th className="t-center fw-smbold">환불액 (VAT 포함)</th>
              <td className="t-right fw-smbold fc-red">
                {changeSolutionData?.existingPayment - changeSolutionData?.usedAmt}원
              </td>
            </tr>
            <tr>
              <th className="t-center fw-smbold">최종 결제 금액 (VAT 포함)</th>
              <td className="t-right fw-smbold fc-red">{changeSolutionData?.payAmt}원</td>
            </tr>
          </tbody>
        </table>
      )}

      <TossPaymentWidget />

      <Box
        sx={{
          mt: 5,
          display: 'flex',
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row-reverse' },
        }}>
        {/* 토스 결제 위젯 오픈 */}
        {!startPayment && (
          <Button disabled={loading} type="submit" variant="solid" color="primary">
            서비스 신청
          </Button>
        )}

        <Button type="button" variant="outlined" color="neutral" onClick={handleClosePopup}>
          닫기
        </Button>
      </Box>
    </form>
  );
}
