import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import instance from '../../apis/Request';
import bizInfoStore from '../../stores/bizInfoStore';
import commonStore from '../../stores/commonStore';
import solutionStore from '../../stores/solutionStore';
import userStore from '../../stores/userStore';
import Loading from '../Util/Loading/Loading';
import { warningBar } from '../Util/SnackBar/SnackBar';
import SellerOfficeLayoutConsulting from './sellerOffice/SellerOfficeLayoutConsulting';
import SellerOfficeLLayoutHeader from './sellerOffice/SellerOfficeLayoutHeader';
import SellerOfficeLayoutLNB from './sellerOffice/SellerOfficeLayoutLNB';
import SellerOfficeLayoutTab from './sellerOffice/SellerOfficeLayoutTab';

const SellerOfficeLayoutComponent = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const { unsetLogin } = userStore();
  const { unsetSolution } = solutionStore();
  const { setInfo } = bizInfoStore();
  const { setMarkets, setSites, setApiKeyList } = commonStore();
  const { setSolutionList, setUserSolution, setChargeDays, setUserPreviousSolution } = solutionStore();
  const navigate = useNavigate();

  const handleToggleMenu = () => setMenuOpen(!menuOpen);

  const getSellerOfficeDefaultInfo = useCallback(async () => {
    try {
      const { data: companyData } = await instance.get('/settings/bizinfo');
      setInfo(companyData.responseData.inquiryQueryResult);

      const { data: marketData } = await instance.get('/common/store');
      setMarkets(marketData.responseData.inquiryResult);

      const { data: siteData } = await instance.get('/common/collectionsite');
      setSites(siteData.responseData.inquiryResult);

      const { data: solutionData } = await instance.get('/solution/productlist');
      setSolutionList(solutionData.responseData.inquiryResult);
      setChargeDays(solutionData?.responseData.useDaysResult);

      const { data: userSolutionData } = await instance.get('/solution/me');
      if (userSolutionData.responseData.inquiryResult.length === 0) {
        navigate('/');
      }
      setUserSolution(userSolutionData.responseData.inquiryResult[0]);
      setUserPreviousSolution(userSolutionData.responseData.previousSolution[0]);

      const {
        data: {
          responseData: { inquiryResult: apiKeyData },
        },
      } = await instance.get('settings/apikey');
      setApiKeyList(apiKeyData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [setInfo, setMarkets, setSites, setSolutionList, setUserSolution, setApiKeyList]);

  const checkUserStatus = useCallback(async () => {
    const {
      data: { responseData },
    } = await instance.get('/solution/me');

    const solutionInfo = responseData?.inquiryResult;

    if (solutionInfo.length === 0) {
      // 로그아웃 처리
      unsetSolution();
      unsetLogin();
      warningBar('솔루션 이용 기간이 만료되어 자동 로그아웃 처리됩니다.');
      navigate('/');
    }
  }, []);

  useEffect(() => {
    getSellerOfficeDefaultInfo();
  }, [getSellerOfficeDefaultInfo]);

  useEffect(() => {
    // 한시간에 한번씩 사용자 상태 체크
    const checkStatus = setInterval(checkUserStatus, 1000 * 60 * 60);
    return () => {
      clearInterval(checkStatus);
    };
  }, [checkUserStatus]);

  return (
    <div className="market">
      <SellerOfficeLLayoutHeader />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="container">
          <SellerOfficeLayoutLNB menuOpen={menuOpen} toggleMenu={handleToggleMenu} />
          <div className={`contents`} style={{ maxWidth: menuOpen ? 'calc(100% - 24rem)' : 'calc(100% - 8rem)' }}>
            <SellerOfficeLayoutTab />
            <Outlet />
            <SellerOfficeLayoutConsulting />
          </div>
        </div>
      )}
    </div>
  );
};

export default SellerOfficeLayoutComponent;
