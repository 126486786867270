const TossPaymentWidget = () => {
  return (
    <>
      <div id="payment-widget" />
      <div id="agreement" />
    </>
  );
};

export default TossPaymentWidget;
