import { Button } from '@mui/joy';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import instance from '../../../../apis/Request';
import commonStore from '../../../../stores/commonStore';
import { popupStore } from '../../../../stores/popupStore';
import solutionStore from '../../../../stores/solutionStore';
import RemindSolution from './RemindSolution';
import { errorBar, successBar, warningBar } from '../../../Util/SnackBar/SnackBar';
import { TossRequestPaymentResult } from '../../../../types/template';
import userStore from '../../../../stores/userStore';
import TossPaymentWidget from '../../../Util/Payment/TossPaymentWidget';
import useTossPaymentWidget from '../../../Util/Payment/hooks/useTossPaymentWidget';

// TODO : 여기도 해야함

export default function ExtensionSolution() {
  const location = useLocation();
  const { closePopup, openPopup } = popupStore();
  const { marketList, siteList } = commonStore();
  const { userSolution, userPreviousSolution, solutionList, chargeDays, refreshUserSolution } = solutionStore();

  const { refreshUserInfo } = userStore();

  const [remainDate, setRemainDate] = useState<number>(0);
  const [monthBill, setMonthBill] = useState<string>('');
  const [accout, setAccount] = useState<number>(0);
  const [saveCount, setSaveCount] = useState<number>(0);
  const [isSelected, setIsSelected] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [startPayment, setStartPayment] = useState<boolean>(false);

  const latestSolution = userSolution || userPreviousSolution;

  console.log('latestSolution', latestSolution);

  const [chargeSolutionInfo, setChargeSolutionInfo] = useState({
    soltProductNo: latestSolution.solutionProductNo,
    solutionUseDayCode: latestSolution?.periodCode || '01',
  });

  const now = dayjs();

  const tossWidget = useTossPaymentWidget({
    initialPrice: 0,
  });

  useEffect(() => {
    if (latestSolution) {
      const date = dayjs(latestSolution?.useEndDate).diff(now, 'days');
      setRemainDate(date);
    }
  }, [now, latestSolution]);

  useEffect(() => {
    if (latestSolution) {
      setMonthBill(latestSolution?.solutionProductAmount.toLocaleString('ko-KR'));
    }
  }, [latestSolution]);

  useEffect(() => {
    if (!latestSolution) return;

    const isUse =
      solutionList.length > 0
        ? solutionList.filter((solution) => solution.solutionName === latestSolution.solutionProductName)?.[0]
        : null;

    if (isUse) {
      setAccount(isUse.uploadAccountCount);
      setSaveCount(isUse.productionSaveCount);
    }
  }, []);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setChargeSolutionInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setIsSelected(true);
  };

  useEffect(() => {
    if (isSelected) setIsSelected(false);
  }, [chargeSolutionInfo, isSelected]);

  const fetchChangeSolution = async () => {
    const response = await instance.get('/solution/change', {
      params: chargeSolutionInfo,
    });

    return response.data.responseData;
  };

  const { data: changeSolutionData } = useQuery({
    queryKey: ['changeSolution'],
    queryFn: fetchChangeSolution,
    enabled: isSelected,
    staleTime: 0,
  });

  const handleClosePopup = () => {
    if (location.pathname === '/') {
      closePopup();
      openPopup('center', '솔루션 만료 안내', <RemindSolution />);
    } else {
      closePopup();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (loading || startPayment) return;

    try {
      setLoading(true);
      setStartPayment(true);

      // 솔루션 구매신청(결재 요청)
      const { data: payReqData } = await instance.post('/solution/request', {
        soltProductNo: chargeSolutionInfo.soltProductNo,
        useDayCode: chargeSolutionInfo.solutionUseDayCode,
        buyType: changeSolutionData.buyType, //
      });

      if (parseInt(payReqData.responseCode) !== 10000) {
        warningBar(payReqData.responseMessage);
        setLoading(false);
        setStartPayment(false);
        return;
      }

      const tossPaymentReqBody = {
        orderId: payReqData.responseData.orderId,
        orderName: '마켓위즈 솔루션 변경',
        customerName: payReqData.responseData.slrName,
        customerEmail: payReqData.responseData.slrEmail,
        // customerMobilePhone: '01011112222', (필수여부 확인 필요)
      };

      setLoading(false);

      // 결재금액 설정
      tossWidget.handlePrice(payReqData.responseData.priceAmt);

      tossWidget.handlePaymentWithCallBack(tossPaymentReqBody, confirmPayment(payReqData.priceNo));
    } catch (error) {
      errorBar('나중에 다시 시도하세요.');
      setStartPayment(false);
    } finally {
      setLoading(false);
    }
  };

  const confirmPayment = (priceNo: string) => async (paymentResult: TossRequestPaymentResult) => {
    const { orderId, paymentKey, amount } = paymentResult;
    if (loading) return;

    try {
      setLoading(true);
      const { data: confirmReqData } = await instance.post('/solution/confirm', {
        priceNo,
        priceAmt: amount,
        paymentKey: paymentKey, //  토스 결제 response
        orderId: orderId, //  토스 결제 response
        buyType: changeSolutionData.buyType,
      });
      console.log('payReqData', confirmReqData);

      successBar('솔루션 변경 신청이 완료되었습니다.');
      await refreshUserInfo();
      await refreshUserSolution();
      closePopup();
    } catch (error) {
      errorBar('나중에 다시 시도하세요.');
    } finally {
      setLoading(false);
      setStartPayment(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="table mt-16">
      <table className="mt-10">
        <colgroup>
          <col style={{ width: '22rem' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: '22rem' }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={3} className="bd-line-2">
              <p className="sub-txt fw-smbold">솔루션 이용현황</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>솔루션명</th>
            <th>이용기간</th>
            <th>월 이용료</th>
          </tr>
          <tr>
            <td>{latestSolution?.solutionProductName}</td>
            <td>
              {latestSolution?.useStartDate} ~ {latestSolution?.useEndDate}
              <span className="fc-red"> ({remainDate - 1}일 남음)</span>
            </td>
            <td>{monthBill}원</td>
          </tr>
        </tbody>
      </table>

      <table className="mt-10">
        <colgroup>
          <col style={{ width: '22rem' }} />
          <col style={{ width: 'auto' }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={2} className="bd-line-2">
              <p className="sub-txt fw-smbold">상세 이용 현황</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>수집 사이트</th>
            <td>
              {siteList.map((site) => {
                return <p key={site.collectionSiteSeq}>{site.collectionSiteName}</p>;
              })}
            </td>
          </tr>
          <tr>
            <th>업로드마켓</th>
            <td className="small-pd">
              {marketList.map((market) => {
                return <p key={market.uploadMarketSeq}>{market.shopName}</p>;
              })}
            </td>
          </tr>
          <tr>
            <th>업로드마켓 계정 수</th>
            <td colSpan={2}>
              <div className="flex space-between align-center">
                <p>
                  이용 {latestSolution?.uploadMarketAccountUseCount} 개 / 잔여 {accout} 개
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <th>상품 저장 수</th>
            <td colSpan={2}>
              <div className="flex space-between align-center">
                <p>
                  이용 {latestSolution?.productSaveUseCount} 개 / 잔여 {saveCount} 개
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="mt-10">
        <thead>
          <tr>
            <th className="bd-line-2">
              <p className="sub-txt fw-smbold">연장 기간 선택</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="flex gap-8">
              {chargeDays.map((day) => {
                return (
                  <div className="radio-box">
                    <input
                      type="radio"
                      className="radio"
                      id={day.dayName}
                      name="solutionUseDayCode"
                      value={day.dayCode}
                      checked={day.dayCode === chargeSolutionInfo.solutionUseDayCode}
                      onChange={handleRadioChange}
                    />
                    <label htmlFor={day.dayName}>{day.dayName}</label>
                  </div>
                );
              })}
            </td>
          </tr>
        </tbody>
      </table>

      <table className="mt-10">
        <colgroup>
          <col style={{ width: '22rem' }} />
          <col style={{ width: 'auto' }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={3} className="bd-line-2">
              <p className="sub-txt fw-smbold">결제 비용 선택</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>결제금액</th>
            <td className="t-right">{changeSolutionData?.totalAmt ?? 0}원</td>
          </tr>
          <tr>
            <th>부가세</th>
            <td className="t-right">{changeSolutionData?.vat ?? 0}원</td>
          </tr>
          <tr>
            <th className="fw-smbold">최종 결제금액</th>
            <td className="t-right fw-smbold fc-red">{changeSolutionData?.payAmt ?? 0}원</td>
          </tr>
        </tbody>
      </table>
      <TossPaymentWidget />

      <Box
        sx={{
          mt: 5,
          display: 'flex',
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row-reverse' },
        }}>
        {!startPayment && (
          <Button disabled={loading} type="submit" variant="solid" color="primary">
            서비스 신청
          </Button>
        )}

        <Button type="button" variant="outlined" color="neutral" onClick={handleClosePopup}>
          닫기
        </Button>
      </Box>
    </form>
  );
}
