import { Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { alertStore } from '../../../stores/alertStore';

export default function Alert() {
  const { isalertOpen, alertContent, closeText, confirmText, confirmEvent, cancelEvent, closeAlert } = alertStore();

  const { pathname } = useLocation();

  useEffect(() => {
    closeAlert();
  }, [pathname, closeAlert]);

  const checkEvent = (excute: any, close: () => void) => async () => {
    if (excute) {
      excute();
      close();
    } else {
      close();
    }
  };

  return (
    <>
      <Transition in={isalertOpen} timeout={400}>
        {(state) => (
          <Modal
            keepMounted
            open={!['exited', 'exiting'].includes(state)}
            onClose={closeAlert}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: 'none',
                  transition: `opacity 400ms`,
                  ...{
                    entering: { opacity: 0.3 },
                    entered: { opacity: 0.3 },
                  }[state],
                },
              },
            }}>
            <ModalDialog
              layout="alert"
              aria-labelledby="nested-modal-title"
              aria-describedby="nested-modal-description">
              <Typography level="h4">{alertContent}</Typography>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  borderTop: '1px solid #eeeeee',
                  textAlign: 'center',
                  margin: 0,
                }}>
                {closeText && (
                  <Button variant="plain" color="cancel" onClick={checkEvent(cancelEvent, closeAlert)}>
                    {closeText}
                  </Button>
                )}
                {confirmText && (
                  <Button variant="plain" color="ok" onClick={checkEvent(confirmEvent, closeAlert)}>
                    {confirmText}
                  </Button>
                )}
              </Box>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </>
  );
}
