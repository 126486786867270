import { create } from 'zustand';

interface AlertState {
  isalertOpen: boolean;
  alertContent: string;
  closeText?: string | null;
  confirmText: string | null;
  confirmEvent?: any;
  cancelEvent: null;
  setConfirmEvent: any;
  setCancelEvent: any;
  openAlert: (
    text: string,
    confirmTextProps?: string,
    closeTextProps?: string,
    confirmEventProps?: any,
    cancelEventProps?: any
  ) => void;
  closeAlert: () => void;
}

export const alertStore = create<AlertState>((set) => ({
  isalertOpen: false,
  alertContent: null,
  closeText: null,
  confirmText: null,
  confirmEvent: null,
  cancelEvent: null,
  setConfirmEvent: (event) =>
    set({
      confirmEvent: event,
    }),
  setCancelEvent: (event) =>
    set({
      cancelEvent: event,
    }),
  openAlert: (text, confirmTextProps, closeTextProps, confirmEventProps, cancelEventProps) =>
    set({
      isalertOpen: true,
      alertContent: text,
      confirmText: confirmTextProps,
      closeText: closeTextProps,
      confirmEvent: confirmEventProps,
      cancelEvent: cancelEventProps,
    }),
  closeAlert: () =>
    set({
      isalertOpen: false,
      alertContent: null,
      confirmText: null,
      confirmEvent: null,
      closeText: null,
      cancelEvent: null,
    }),
}));
