// 팝업으로 나타나는 컴포넌트들 분리
const FooterPolicy = () => {
  return (
    <div>
      <div className="search-wrap scroll-y mt-16">
        <p>마켓위즈 이용약관을 안내드립니다.</p>
        <br />
        <p>제 1 조 (목적)</p>
        <p>
          본 약관은 (주)마켓위즈(이하 “회사”라 한다)가 제공하는 마켓위즈(www.www.com) 서비스(이하 “서비스”라 한다)에
          관한 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
        </p>
        <p>제 2 조 (약관의 효력 및 변경)</p>
        <p>
          1. 본 약관은 “이용자”에게 “서비스” 화면에 게시하거나, 기타의 방법으로 공지하는 것으로 효력이 발생합니다. 2.
          “회사”는 약관의 규제에 관한 법률 및 기타 관련 법령에 위배되지 않는 범위에서 본 약관의 내용을 개정할 수 있으며,
          변경된 경우에는 제1항과 같은 방법으로 공지합니다. 다만 “이용자”의 권리와 의무에 관한 중요한 사항은 변경된
          내용의 시행 15일 이전에 공지합니다. 3. “이용자”가 변경된 약관에 대한 내용을 알지 못하여 발생하는 손해 및
          피해에 대해서는 “회사”가 책임을 지지 않습니다.
        </p>
        <p>제 3 조 (약관 외 준칙)</p>
        <p>
          BO에서 입력한 개인정보처리방침 내용이 보입니다. BO에서 입력한 개인정보처리방침 내용이 보입니다. BO에서 입력한
          개인정보처리방침내용이 보입니다. BO에서 입력한 개인정보처리방침 내용이 보입니다.
        </p>
        <p>BO에서 입력한 개인정보처리방침 내용이 보입니다.</p>
      </div>
    </div>
  );
};

const FooterPernoal = () => {
  return (
    <div>
      {/* <div className="flex">
        <div className="select-box mr-21">
          <button className="init">게시일: 2023.12.30</button>
          <ul>
            <li>
              <button data-value="value1">Hover</button>
            </li>
            <li>
              <button data-value="value2">Selected</button>
            </li>
            <li>
              <button data-value="value3">Default</button>
            </li>
            <li>
              <button data-value="value4">Disabled</button>
            </li>
          </ul>
        </div>
      </div> */}

      <div className="search-wrap scroll-y mt-16">
        <p>마켓위즈 이용약관을 안내드립니다.</p>
        <br />
        <p>제 1 조 (목적)</p>
        <p>
          본 약관은 (주)마켓위즈(이하 “회사”라 한다)가 제공하는 마켓위즈(www.www.com) 서비스(이하 “서비스”라 한다)에
          관한 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
        </p>
        <p>제 2 조 (약관의 효력 및 변경)</p>
        <p>
          1. 본 약관은 “이용자”에게 “서비스” 화면에 게시하거나, 기타의 방법으로 공지하는 것으로 효력이 발생합니다. 2.
          “회사”는 약관의 규제에 관한 법률 및 기타 관련 법령에 위배되지 않는 범위에서 본 약관의 내용을 개정할 수 있으며,
          변경된 경우에는 제1항과 같은 방법으로 공지합니다. 다만 “이용자”의 권리와 의무에 관한 중요한 사항은 변경된
          내용의 시행 15일 이전에 공지합니다. 3. “이용자”가 변경된 약관에 대한 내용을 알지 못하여 발생하는 손해 및
          피해에 대해서는 “회사”가 책임을 지지 않습니다.
        </p>
        <p>제 3 조 (약관 외 준칙)</p>
        <p>
          BO에서 입력한 개인정보처리방침 내용이 보입니다. BO에서 입력한 개인정보처리방침 내용이 보입니다. BO에서 입력한
          개인정보처리방침내용이 보입니다. BO에서 입력한 개인정보처리방침 내용이 보입니다.
        </p>
        <p>BO에서 입력한 개인정보처리방침 내용이 보입니다.</p>
      </div>
    </div>
  );
};

const FooterWithDraw = () => {
  return (
    <div>
      {/* <div className="flex">
          <div className="select-box mr-21">
            <button className="init">게시일: 2023.12.30</button>
            <ul>
              <li>
                <button data-value="value1">Hover</button>
              </li>
              <li>
                <button data-value="value2">Selected</button>
              </li>
              <li>
                <button data-value="value3">Default</button>
              </li>
              <li>
                <button data-value="value4">Disabled</button>
              </li>
            </ul>
          </div>
        </div> */}

      <div className="search-wrap scroll-y mt-16">
        <p>솔루션 취소와 환불솔루션 환불은 아래와 같이 적용된다.</p>
        <br />
        <p>
          환불 방식요금제 업그레이드/다운그레이드 시 요금제 업그레이드/다운그레이드 시 일할계산 하여 환불처리되며 새롭게
          부과되는 월 이용료도 일할계산하여 적용되어 최종 과금의 경우 (변경후 요금제 - 변경전 요금제(일할계산) =
          최종결제금액) 으로 적용된다.
        </p>
        <br />
        <p>
          다운그레이드 시 결제금액보다 환불금액이 클 경우 결제한 수단으로 환불처리된다.환불금액은 결제할때와 동일한
          수단으로 환불처리되며 환불처리는 결제수단에 따라 며칠 소요될 수 있다.중도해지 수수료 10% 회원탈퇴시 부과 후
          환불처리된다. 
        </p>
        <br />
        <p style={{ paddingLeft: '1rem' }}>
          * 환불 금액 = 변경전 요금제 - 환불수수료(변경전요금제 10%) - 기이용료(변경전 요금제 일할계산분)부가세
        </p>
      </div>
    </div>
  );
};

const FooterAscro = () => {
  return <div>애스크로 서비스 가입 확인 내용입니다.</div>;
};

export { FooterAscro, FooterPernoal, FooterPolicy, FooterWithDraw };
