import axios, { AxiosError } from 'axios';
import { errorBar, warningBar } from '../components/Util/SnackBar/SnackBar';
import globalRouter from '../globalRouter';
import userStore from '../stores/userStore';

type responseDataType = {
  responseCode: number;
  responseMessage: string;
  responseData: any;
};

const baseURL = '/api/v1';
const timeout = 60000 * 10;

const instance = axios.create({
  timeout: timeout,
  baseURL,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Env: process.env.REACT_APP_ENV,
    },
  },
  maxContentLength: 100000000,
  maxBodyLength: 100000000,
});

// axios base setting
instance.interceptors.request.use(
  (config) => {
    // const accessToken = getToken();
    // 참고링크 https://stackoverflow.com/questions/75530833/how-to-call-zustand-react-hook-outside-of-react-component
    const accessToken = userStore.getState().getUserInfo().userSession;

    if (accessToken !== null || accessToken !== '') {
      config.headers['UserSession'] = accessToken;
      config.headers['Authorization'] =
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJubyI6MSwiZW1haWwiOiJoeWtpbUB3YXZlZGVjay5haSIsInR5cGUiOiJ3YXZlZGVjayIsIm5pY2tuYW1lIjoiYWhzZXVpZmhhbHNldWZobmFsa3NqZGhmbGFrc3VlaGZsa2F1c2RoZiIsInByb2ZpbGVfaW1nIjoicHJvZmlsZUltYWdlL2RlZmF1bHRwcm9maWxlLzEucG5nIiwic3RhdHVzIjowLCJpYXQiOjE3MzM4OTI4NDcsImV4cCI6MTczMzg5NjQ0N30.5IOS2oOT1Htte6eP6-nWg2Bpa-6f3GDLGZ-SJFZiJPQ';
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    console.log('instance.interceptors.response', response);
    if (response.status === 200) {
      const { responseCode } = response?.data as responseDataType;
      if ((response?.data as responseDataType)?.responseCode !== 10000) {
        // "응답 코드에 따른 예외처리 및 알림"
        switch (responseCode) {
          case 10001:
            warningBar('필수값 입력이 누락되었습니다. 입력값을 다시 확인해 주세요.');
            break;
          case 10009:
            warningBar('솔루션 신청을 먼저 진행하세요.');
            globalRouter.navigate('/member/register-solution/');
            break;
          case 10013:
            errorBar('세션이 만료되어 자동 로그아웃 처리 됩니다.');
            userStore.getState().unsetLogin();
            globalRouter.navigate('/auth/login');
            break;
          case 10014:
            warningBar('사업자 정보 확인에 실패했습니다.');
            globalRouter.navigate('/');
            break;
          case 10037:
            warningBar('저장 가능한 상품 수가 초과되었습니다.');
            break;
          case 10018:
            warningBar('API KEY 검증이 실패했습니다.');
            break;
        }
      }

      return response;
    }
  },
  (error: AxiosError) => {
    if (error) {
      if ((error?.response?.data as responseDataType)?.responseCode === 503) {
        errorBar('서버 에러 발생.');
        console.log('AxiosError', error);
      }
      return Promise.reject(error);
    }
  }
);

export default instance;
