import { create } from 'zustand';
import { BizType } from '../types/biz';

type bizInfoType = {
  bizInfo: BizType;
  setInfo: (info: BizType) => void;
  unsetInfo: () => void;
};

const initBizinfo = {
  userEmail: '',
  channel: '',
  userName: '',
  phoneNo: '',
  regDate: '',
  spmName: '',
  spmImg: '',
  spmTelNo: '',
  entName: '',
  entClass: '',
  brNo: '',
  bizState: '',
  item: '',
  zip: '',
  addr: '',
  addrDetail: '',
  rprsvName: '',
  rprsvTelNo: '',
  infoSecOfficer: '',
  infoSecOfficerTelNo: '',
  recommandUser: null,
  recommanderList: '',
};

const bizInfoStore = create<bizInfoType>((set) => ({
  bizInfo: {
    ...initBizinfo,
  },

  setInfo: (info: BizType) => set({ bizInfo: { ...info } }),
  unsetInfo: () =>
    set(
      {
        bizInfo: {
          ...initBizinfo,
        },
      },
      true
    ),
}));

export default bizInfoStore;
