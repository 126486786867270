import { useCallback, useEffect, useRef, useState } from 'react';

import { PaymentWidgetInstance, loadPaymentWidget } from '@tosspayments/payment-widget-sdk';

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY;
const customerKey = process.env.REACT_APP_TOSS_CUSTOMER_KEY;

interface UseTossPaymentWidgetProps {
  initialPrice: number;
}

// TODO: refactoring
const useTossPaymentWidget = ({ initialPrice }: UseTossPaymentWidgetProps) => {
  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);
  const paymentMethodsWidgetRef = useRef<ReturnType<PaymentWidgetInstance['renderPaymentMethods']> | null>(null);
  const [price, setPrice] = useState(initialPrice);

  // ------  결제위젯 초기화 ------
  // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
  // const paymentWidget = use(loadPaymentWidget(clientKey, ANONYMOUS)); // 비회원 결제

  useEffect(() => {
    const init = async () => {
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey); // 회원 결제
      // ------  결제위젯 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        '#payment-widget',
        { value: price },
        { variantKey: 'DEFAULT' }
      );

      // ------  이용약관 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
      paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' });
      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    };
    init();
  }, [paymentWidgetRef, price]);

  const handlePrice = useCallback((_price: number) => {
    setPrice(_price);
  }, []);

  const handlePaymentWithCallBack = async (params: any | undefined, callbackFunc: any | undefined) => {
    const paymentWidget = paymentWidgetRef.current;
    if (typeof params === 'undefined') {
      // {
      //     orderId: '마켓위즈 기본세팅',
      //     orderName: '2개월 2건 추가',
      //     customerName: '엔터플',
      //     customerEmail: 'jayden@nntuple.com',
      //     customerMobilePhone: '01012341234',
      //   }
      return;
    }
    try {
      const paymentResult = await paymentWidget?.requestPayment(params);
      if (paymentResult) {
        if (typeof callbackFunc === 'function') {
          callbackFunc(paymentResult);
        }
      }
    } catch (error) {
      // 에러 처리하기
      // payment-failure
      console.error(error, price, params);
    }
  };

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;
    if (paymentMethodsWidget == null) {
      return;
    }
    // ------ 금액 업데이트 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
    paymentMethodsWidget.updateAmount(price);
  }, [price]);

  return {
    paymentWidgetRef,
    price,
    handlePrice,
    handlePaymentWithCallBack,
  };
};

export default useTossPaymentWidget;
