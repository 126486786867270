import { Box, Button } from '@mui/joy';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { popupStore } from '../../../../stores/popupStore';
import solutionStore from '../../../../stores/solutionStore';
import ChangeSolution from './ChangeSolution';
import ExtensionSolution from './ExtensionSolution';

export default function RemindSolution() {
  const { userSolution, userPreviousSolution, solutionList } = solutionStore();
  const { openPopup } = popupStore();
  const now = dayjs();

  //

  const latestSolution = userSolution || userPreviousSolution;

  const endDt = dayjs(latestSolution.useEndDate),
    ended = now.diff(endDt, 'days') > 0;

  console.log('diffdiff', ended);

  const handleChange = () => {
    openPopup('panel', '솔루션 변경', <ChangeSolution solutionNo={latestSolution?.solutionProductNo} />);
  };
  const handleExtension = () => {
    openPopup('panel', '솔루션 기간 연장', <ExtensionSolution />);
  };

  const [marketAccount, setMarketAccount] = useState<number>();
  const [saveProduct, setSaveProduct] = useState<number>();

  useEffect(() => {
    if (!latestSolution) return;

    const isUse =
      solutionList.length > 0
        ? solutionList.filter((solution) => solution.solutionName === latestSolution.solutionProductName)?.[0]
        : null;

    if (isUse) {
      setMarketAccount(isUse.uploadAccountCount);
      setSaveProduct(isUse.productionSaveCount);
    }
  }, []);

  return (
    <>
      <p className="mt-16 t-center pd-10-x fw-smbold">
        이용중인 솔루션이 <span className="fc-red">{latestSolution.useEndDate}</span> 일로 종료(예정)이며,
        {!ended && (
          <>
            {' '}
            <span className="fc-red">{dayjs(latestSolution.useEndDate).add(1, 'day').format('YYYY-MM-DD')}</span> 일부터
          </>
        )}{' '}
        마켓위즈 셀러오피스 사용이 불가합니다.
        <br />
        기간 연장 또는 솔루션 결제를 통해 이용하시는 솔루션을 유지하시길 바랍니다.
      </p>
      <div className="table mt-16">
        <table>
          <colgroup>
            <col style={{ width: '23rem' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>솔루션 종류</th>
              <td className="small-pd">{latestSolution?.solutionProductName}</td>
            </tr>
            <tr>
              <th>월 이용료</th>
              <td className="small-pd">{latestSolution?.solutionProductAmount} 원</td>
            </tr>
            <tr>
              <th>이용기간</th>
              <td className="small-pd">{latestSolution?.period} 일</td>
            </tr>

            <tr>
              <th>마켓계정 수 (사용중 / 사용가능)</th>
              <td className="small-pd">
                {latestSolution?.uploadMarketAccountUseCount} 개 / {marketAccount} 개
              </td>
            </tr>
            <tr>
              <th>상품저장 수 (사용중 / 사용가능)</th>
              <td className="small-pd">
                {latestSolution?.productSaveUseCount} 개 / {saveProduct} 개
              </td>
            </tr>
          </tbody>
        </table>
        <p className="td-notice mt-8">
          솔루션이 만료될 경우 일부 정보는 삭제될 수 있으며, 삭제된 정보는 복구되지 않습니다.
        </p>

        <Box
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
          }}>
          <Button variant="solid" color="primary" onClick={handleChange}>
            솔루션 결제
          </Button>
          <Button
            variant="outlined"
            color="neutral"
            onClick={handleExtension}
            sx={{
              color: '#fff',
              ':hover': {
                background: '#202020',
              },
              background: '#42444e',
            }}>
            기간 연장
          </Button>
        </Box>
      </div>
    </>
  );
}
