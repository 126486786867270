import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import tabStore from '../../../../stores/tabStore';
import { LNBPropsType } from '../../../../types/LNBPropsType';

const prefPath = '/sellerOffice/manageCollection';

export default function LNBSelect(props: LNBPropsType) {
  const { menuOpen } = props;
  const { items: tabItems, addItem, activeItemByUrl } = tabStore();

  const location = useLocation();
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const [depActive, setDepActive] = useState<boolean[]>([false, false]);

  const handleClickMenu = () => setMenuActive(!menuActive);

  const handleTabList = (e) => {
    const targetPath = e.view.location.pathname;

    const existItemIndex = tabItems.findIndex((item) => item.linkUrl === targetPath);
    if (existItemIndex === -1) {
      addItem({
        linkUrl: e.view.location.pathname,
        name: e.target.innerText,
        active: true,
      });
    }

    activeItemByUrl(e.view.location.pathname);
  };
  const handleClickDepth = (i: number) => () => {
    const dep = depActive.slice();
    dep[i] = !dep[i];
    setDepActive(dep);
  };

  useEffect(() => {
    const dep = [false];

    setMenuActive(location.pathname.includes(prefPath) ? true : false);
    switch (true) {
      case [`${prefPath}/collect`, `${prefPath}/banword`, `${prefPath}/eachsite`].includes(location.pathname):
        dep[0] = true;
        break;
    }

    setDepActive(dep);
  }, [setMenuActive, location]);

  return (
    <li className={menuActive ? 'open' : ''}>
      <div className="folding-head">
        <div onClick={handleClickMenu} className="folding-btn">
          <i className="ico-manage-collect"></i>상품수집{menuOpen ? '관리' : ''}
        </div>
      </div>
      <div className="folding-panel">
        <ul>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to={'manageCollection/siteCollect'}>사이트 상품수집</Link>
          </li>
          <li onClick={handleClickDepth(0)} className={depActive[0] ? 'has-depth active' : 'has-depth'}>
            상품 수집 설정
            <i className="depth-open"></i>
            <ul>
              <li onClick={handleTabList} className="lnb-menu">
                <Link to={'manageCollection/collect'}>상품 수집 설정</Link>
              </li>
              <li onClick={handleTabList} className="lnb-menu">
                <Link to={'manageCollection/banword'}>금지어 설정</Link>
              </li>
              <li onClick={handleTabList} className="lnb-menu">
                <Link to={'manageCollection/eachsite'}>수집사이트별 설정 관리</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
  );
}
