import Close from '@mui/icons-material/Close';
import toast from 'react-hot-toast';

export const defaultBar = (message: string) => {
  toast((t) => <DefaultSnackBar message={message} id={t.id} />, {
    duration: 4000,
    position: 'bottom-left',
    style: {},
  });
};

export const successBar = (message: string) => {
  toast((t) => <DefaultSnackBar message={message} id={t.id} />, {
    duration: 4000,
    position: 'bottom-left',
    style: {
      background: '#FBFCFE',
      color: '#1F7A1F',
      border: '1px solid #A1E8A1',
    },
  });
};

export const errorBar = (message: string) => {
  toast((t) => <DefaultSnackBar message={message} id={t.id} />, {
    duration: 4000,
    position: 'bottom-left',
    style: {
      background: '#FBFCFE',
      color: '#C41C1C',
      border: '1px solid #F09898',
    },
  });
};

export const warningBar = (message: string) => {
  toast((t) => <DefaultSnackBar message={message} id={t.id} />, {
    duration: 4000,
    position: 'bottom-left',
    style: {
      background: '#FBFCFE',
      color: '#9A5B13',
      border: '1px solid #F3C896',
    },
  });
};

export const infoBar = (message: string) => {
  toast((t) => <DefaultSnackBar message={message} id={t.id} />, {
    duration: 4000,
    position: 'bottom-left',
    style: {
      background: '#FBFCFE',
      color: '#32383E',
      border: '1px solid #CDD7E1',
    },
  });
};

export default function DefaultSnackBar({ id, message }: { id: string; message: string }) {
  const handleClose = () => {
    toast.dismiss(id);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0.5rem',
        minWidth: '26rem',
        width: '100%',
        minHeight: '4rem'
      }}>
      <p
        style={{
          fontSize: '1.5rem',
          fontWeight: '600',
          textWrap: 'wrap',
          lineHeight: '1.5',
          width: '100%',
        }}>
        {message}
      </p>
      <button style={{ minWidth: '2rem', height: '2.2rem', flexBasis: '1' }} onClick={handleClose}>
        <Close style={{ width: '100%', height: '100%', color: '#636363ce' }} />
      </button>
    </div>
  );
}
