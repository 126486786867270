import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { aesDecrypt } from '../Utils/commonUtils';
import instance from '../apis/Request';

export type socialType = 'naver' | 'kakao' | 'google' | null;
export type userInfoType = {
  socialType: socialType;
  userSession: string;
  userEmail: string;
  slrSeq: number | null;
  spmName?: string | null;
  solutionEndDate?: string | null;
  soltHistory: boolean | null;
};

export type loginParamType = {
  socialType: socialType;
  encryptStr: string;
};

type userStoreType = loginParamType & {
  socialType: socialType;
  encryptStr: string;
  setLogin: (userInfo: loginParamType) => void;
  unsetLogin: () => void;
  getUserInfo: () => userInfoType;
  refreshUserInfo: () => Promise<void>;
};

// export const SESSION_USER_KEY = enc.Base64.stringify(enc.Utf8.parse('user_key'));

// 로그인 이후 회원정보를 관리
// sessionStorage에 유저 정보를 전역 상태로 저장
const userStore = create<userStoreType, [['zustand/persist', userStoreType]]>(
  persist(
    (set, get) => ({
      socialType: null,
      encryptStr: '',
      //   userEmail: '',
      //   slrSeq: null,
      //   spmName: null,

      setLogin: (userInfo: loginParamType) =>
        set(() => {
          // TODO : 연동시 수정필요
          return {
            socialType: userInfo.socialType,
            encryptStr: userInfo.encryptStr,
          };
        }),
      unsetLogin: () =>
        set(() => {
          // sessionStorage.removeItem(SESSION_USER_KEY);
          return {
            socialType: null,
            encryptStr: '',
          };
        }),

      getUserInfo: () => {
        const encStr = get().encryptStr;
        const socialType = get().socialType;
        if (encStr.length === 0) {
          return {
            userSession: '',
            userEmail: '',
            slrSeq: null,
            spmName: '',
            socialType: null,
            solutionEndDate: null,
            soltHistory: null,
          };
        }
        const userData: userInfoType = JSON.parse(aesDecrypt(encStr));

        return { socialType, ...userData };
      },
      refreshUserInfo: async () => {
        const { data } = await instance.get('/login/session');
        set(() => ({
          encryptStr: data.responseData,
        }));
      },
    }),
    { name: 'user', storage: createJSONStorage(() => sessionStorage) }
  )
);

export default userStore;
