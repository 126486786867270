import { Link, useLocation } from 'react-router-dom';
import { LNBPropsType } from '../../../../types/LNBPropsType';
import { useEffect, useState } from 'react';
import tabStore from '../../../../stores/tabStore';

const orderPath = '/sellerOffice/manageOrder';

export default function LNBOrder(props: LNBPropsType) {
  const { menuOpen } = props;

  const location = useLocation();

  const [menuActive, setMenuActive] = useState(false);

  const { items: tabItems, addItem, activeItemByUrl } = tabStore();

  const handleTabList = (e) => {
    const targetPath = e.view.location.pathname;

    const existItemIndex = tabItems.findIndex((item) => item.linkUrl === targetPath);
    if (existItemIndex === -1) {
      addItem({
        linkUrl: e.view.location.pathname,
        name: e.target.innerText,
        active: true,
      });
    }

    activeItemByUrl(e.view.location.pathname);
  };

  const handleClickMenu = () => setMenuActive(!menuActive);
  useEffect(() => {
    setMenuActive(location.pathname.includes(orderPath) ? true : false);
  }, [setMenuActive, location]);

  return (
    <li className={menuActive ? 'open' : ''}>
      <div className="folding-head">
        <div onClick={handleClickMenu} className="folding-btn">
          {/* Order/Claim Management */}
          <i className="ico-manage-claim"></i>
          {menuOpen ? '주문·클레임관리' : '주문·관리'}
        </div>
      </div>
      <div className="folding-panel">
        <ul>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to={'manageOrder/orderResult'}>주문 수집 관리</Link>
          </li>
        </ul>
      </div>
    </li>
  );
}
