import { create } from 'zustand';

interface PopupState {
  popupTitle: string | null; // 팝업 컴포넌트의 제목
  isPopupOpen: boolean; // 팝업 오픈 여부
  popupContent: JSX.Element | null; // 팝업에 나타날 전체 내용
  outlineText?: string | null; // 속이 비고, 겉 테두리가 있는 버튼 내용
  outlineEvent?: any; // 속이 비고, 겉 테두리가 있는 버튼 이벤트 함수
  solidText: string | null; // 속이 차고, 겉 테두리가 없는 버튼 내용
  solidEvent?: any; // 속이 차고, 겉 테두리가 없는 버튼 이벤트 함수
  popupLayout: 'center' | 'panel' | 'full';
  setSolidEvent: any;
  setOutlineEvent: any;
  useBottomButton: boolean;
  openPopup: (
    layoutProps: 'center' | 'panel' | 'full',
    title: string, // 팝업 컴포넌트의 제목
    content: JSX.Element, // 팝업에 나타날 전체 내용
    solidTextProps?: string, // 속이 차고, 겉 테두리가 없는 버튼 내용
    outlineTextProps?: string, // 속이 비고, 겉 테두리가 있는 버튼 내용
    bottomButton?: boolean
    // solidEventProps?: <T extends {}>(arg?: T) => void, // 속이 차고, 겉 테두리가 없는 버튼 이벤트 함수
    // outlineEventProps?: <T extends {}>(arg?: T) => void // 속이 비고, 겉 테두리가 있는 버튼 이벤트 함수
  ) => void;
  closePopup: () => void; // 팝업 닫는 함수
}

export const popupStore = create<PopupState>((set) => ({
  popupTitle: null,
  isPopupOpen: false,
  popupContent: null,
  outlineText: null,
  solidText: null,
  outlineEvent: null,
  // solidEvent: {
  //   method: null,
  //   api: null,
  //   body: null
  // },
  solidEvent: null,
  popupLayout: 'center',
  useBottomButton: true,
  // setSolidEvent: (method, api, body) =>
  //   set({
  //     solidEvent: {
  //       method: method,
  //       api: api,
  //       body: body
  //     },
  //   }),
  setSolidEvent: (event) =>
    set({
      solidEvent: event,
    }),
  setOutlineEvent: (event) =>
    set({
      outlineEvent: event,
    }),
  openPopup: (layoutProps, title, content, solidTextProps, outlineTextProps, bottomButton) =>
    set({
      popupLayout: layoutProps,
      popupTitle: title,
      isPopupOpen: true,
      popupContent: content,
      solidText: solidTextProps,
      outlineText: outlineTextProps,
      useBottomButton: typeof bottomButton === 'undefined' ? true : bottomButton,
    }),
  closePopup: () =>
    set({
      popupTitle: null,
      isPopupOpen: false,
      popupContent: null,
      solidText: null,
      solidEvent: null,
      outlineText: null,
      outlineEvent: null,
      useBottomButton: true,
    }),
}));
