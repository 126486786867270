import { Box, Button } from '@mui/joy';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import instance from '../../apis/Request';
import { alertStore } from '../../stores/alertStore';
import { popupStore } from '../../stores/popupStore';
import userStore from '../../stores/userStore';
import { errorBar, infoBar, successBar } from '../Util/SnackBar/SnackBar';
import solutionStore from '../../stores/solutionStore';

export default function WithdrawMember() {
  const { closePopup } = popupStore();
  const { unsetLogin } = userStore();
  const { unsetSolution } = solutionStore();
  const { openAlert, closeAlert } = alertStore();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationKey: ['withdrawMember'],
    mutationFn: async () => {
      const response = await instance.post('/login/withdrawal');

      return response.data;
    },
    onSuccess: (data) => {
      switch (data.responseCode) {
        case 10015:
          errorBar('회원 탈퇴에 실패했습니다. 잠시 후 다시 시도해주세요.');
          break;
        case 10000:
          successBar('회원 탈퇴가 완료되었습니다.');
          closePopup();
          closeAlert();
          unsetSolution();
          unsetLogin();
          queryClient.invalidateQueries({ queryKey: ['userInfo'] });
          navigate('/');
          break;
        case 10036:
          infoBar('회원 탈퇴가 불가능합니다. 사용중인 솔루션 상품이 존재합니다.');
          navigate('/');
          break;
        default:
          errorBar('회원 탈퇴에 실패했습니다. 잠시 후 다시 시도해주세요.');
          break;
      }
    },
    onError(error) {
      console.log(error);
      errorBar('회원 탈퇴에 실패했습니다. 잠시 후 다시 시도해주세요.');
    },
  });

  const handleWithdrawMember = () => {
    const handleWithdrawConfirm = () => {
      mutate();
    };

    openAlert('정말 탈퇴하시겠습니까?', '확인', '아니오', handleWithdrawConfirm);
  };

  return (
    <div className="table mt-16">
      <strong className="fc-red">잠깐! 고객님, 탈퇴 시 유의사항을 확인해주세요.</strong>

      <table className="mt-6">
        <colgroup>
          <col style={{ width: '14rem' }} />
          <col style={{ width: 'auto' }} />
        </colgroup>
        <thead>
          <tr>
            <th colSpan={2} className="bd-line-2">
              <p className="sub-txt">
                회원 탈퇴 시 회원님의 마켓위즈 이용정보가 삭제되어 복구가 불가능합니다. 신중히 선택하시길 바랍니다.
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th rowSpan={3}>회원 정보 삭제</th>
            <td>회원 탈퇴 후 동일한 아이디(이메일)로는 14일 이내 재가입 할 수 없습니다.</td>
          </tr>
          <tr>
            <td>회원 탈퇴와 함께 마켓위즈에 등록된 모든 개인정보는 삭제, 폐기되며 복구되지 않습니다.</td>
          </tr>
          <tr>
            <td>
              단, 상법 및 "전자상거래 등에서 소비자 보호에 관한 법률" 등 관련 법령의 규정에 의하여 아래와 같이 일정
              기간동안 보관됩니다.
              <br />- 계약 또는 청약 철최 등에 관한 기록 : 5년
              <br />- 대금결제 및 재화 등의 공급에 관한 기록 : 5년
              <br />- 소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년
              <br />- 설문조사, 이벤트 등 일시적 목적을 위하여 수집된 정보 : 해당 설문조사, 이벤트 등이 종료된 당해
              연도까지
            </td>
          </tr>
          <tr>
            <th rowSpan={2}>
              구매한 서비스 및<br />
              상품 등 자산의 소멸
            </th>
            <td>
              회원 탈퇴와 함께 마켓위즈에 저장된 모든 개인정보가 삭제 됨에 따라 이용기한이 잔존하는 모든 서비스 및 상품
              등 또한 함께 소멸 됩니다.
            </td>
          </tr>
          <tr>
            <td>
              마켓위즈는 별도의 환불 서비스를 제공하지 않으며, 회원 탈퇴 시 소멸된 자산은 복구 및 재이용이 불가능
              합니다.
            </td>
          </tr>
          <tr>
            <th rowSpan={2}>
              탈퇴 회원과
              <br />
              연결된 계정
            </th>
            <td>
              탈퇴한 회원이 메인 관리자 역할을 수행하고 있었다면, 마켓위즈에 저장된 모든 개인정보와 기업정보가 삭제 됨에
              따라 연결된 모든 부관리자 또한 는 그 권한이 자동 해지 됩니다.
            </td>
          </tr>
        </tbody>
      </table>

      <Box
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
        }}>
        <div></div>

        <Button variant="solid" color="primary" onClick={handleWithdrawMember}>
          회원 탈퇴
        </Button>
      </Box>
    </div>
  );
}
