import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import tabStore from '../../../../stores/tabStore';
import { LNBPropsType } from '../../../../types/LNBPropsType';
import { LNBMenuList } from '../../../Util/Function/function';

const productPath = '/sellerOffice/manageProduct';

export default function LNBProduct(props: LNBPropsType) {
  const { menuOpen } = props;
  const location = useLocation();

  const [menuActive, setMenuActive] = useState<boolean>(false);

  const handleClickMenu = () => setMenuActive(!menuActive);

  const { items: tabItems, addItem, activeItemByUrl } = tabStore();

  const handleTabList = (e) => {
    const targetPath = e.view.location.pathname;

    const existItemIndex = tabItems.findIndex((item) => item.linkUrl === targetPath);
    if (existItemIndex === -1) {
      addItem({
        linkUrl: e.view.location.pathname,
        name: e.target.innerText,
        active: true,
      });
    }

    activeItemByUrl(e.view.location.pathname);
  };

  useEffect(() => {
    setMenuActive(location.pathname.includes(productPath) ? true : false);
  }, [setMenuActive, location]);

  return (
    <li className={menuActive ? 'open' : ''}>
      <div className="folding-head">
        <div onClick={handleClickMenu} className="folding-btn">
          <i className="ico-manage-product"></i>상품{menuOpen ? '관리' : ''}
        </div>
      </div>
      <div className="folding-panel">
        <ul>
          {LNBMenuList.map((_, i) => {
            return (
              <li key={`menu_${i}`} onClick={handleTabList} className="lnb-menu">
                <Link to={`manageProduct/${_.link}`}>{_.title}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  );
}
