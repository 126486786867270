import { AES, enc, mode } from 'crypto-js';
import instance from '../apis/Request';
import { ORDER_STATUS, translateCode } from '../constant/commonConst';
import commonStore from '../stores/commonStore';
import userStore from '../stores/userStore';
import axios from 'axios';
import { warningBar } from '../components/Util/SnackBar/SnackBar';

export type imgTransReqType = {
  imgType: string;
  imgStr: string;
  imgName: string; // 네이밍규칙??
  sourceLang: string;
  targetLang: string;
};

export const aesDecrypt = (encryptedData: string): string => {
  const keyStr = process.env.REACT_APP_ENCRYPT_KEY;
  const ivStr = process.env.REACT_APP_ENCRYPT_iv;

  const cipher = AES.decrypt(
    enc.Base64.parse(encryptedData).toString(enc.Utf8),
    enc.Utf8.parse(keyStr.padEnd(32, '\0')),
    {
      iv: enc.Utf8.parse(ivStr),
      mode: mode.CBC,
    }
  );

  return cipher.toString(enc.Utf8);
};

export const division = <T>(arr: T[], count: number): T[][] => {
  const divide = Math.ceil(arr.length / count);
  const newArray = [];

  for (let i = 0; i < divide; i++) {
    // 배열 0부터 n개씩 잘라 새 배열에 넣기
    newArray.push(arr.splice(0, count));
  }

  return newArray;
};

export const numberFormat = new Intl.NumberFormat('ko-KR');

export const getBanwordTemplates = async () => {
  const { data } = await instance.get('settings/banwordTemplate');
  return data.responseData.banwordTemplateList;
};

export const checkParseInt = (val: string | number) => (typeof val === 'string' ? parseInt(val) : val);

export const getByte = (str: string) =>
  str
    .split('')
    .map((s) => s.charCodeAt(0))
    .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);

export const translateImg = async (params) => {
  try {
    if (!userStore.getState().getUserInfo().slrSeq) {
      warningBar('로그인이 필요한 기능입니다.');
      return false;
    }
    if (
      !commonStore.getState().apiKeyList?.ohooInfo?.apiVal ||
      commonStore.getState().apiKeyList?.ohooInfo?.apiVal === null
    ) {
      warningBar('오후 스튜디오 api key를 입력하세요.');
      return false;
    }
    const header = {
      'X-OHOO-API-KEY': commonStore.getState().apiKeyList.ohooInfo.apiVal,
      'X-OHOO-SUB-USER': userStore.getState().getUserInfo().slrSeq,
    };

    const { data } = await axios.post('/studio/v1/image/translate', params, { headers: { ...header } });
    return data.responses;
  } catch (error) {
    console.error(error);
    warningBar('나중에 다시 시도하세요.');
    return false;
  }
};

//
export const uploadImage = async (imgData: string, uploadFolder: string, mimeType: string) => {
  const { data: imgUploadResponse } = await instance.post('/common/uploadImage', {
    imgData: imgData,
    imgFolder: uploadFolder,
    mimeType: mimeType,
  });

  return imgUploadResponse.responseData.url;
};

export const getMimeType = (contentType: string) => {
  let mimeType = '';
  switch (contentType) {
    case 'image/png':
      mimeType = 'png';
      break;
    case 'image/jpg':
    case 'image/jpeg':
      mimeType = 'jpg';
      break;

    case 'image/gif':
      mimeType = 'gif';
      break;
    case 'image/webp':
      mimeType = 'webp';
      break;
  }

  return mimeType;
};

const getUrlCleamParam = (url: string) => {
  const urlObj = new URL(url);
  return `${urlObj.origin}${urlObj.pathname}`;
};

export const uploadImageNew = async (imgData: string, uploadFolder: string, contentType: string) => {
  const mimeType = getMimeType(contentType);

  // S3 PreSignedUrl 생성
  const { data: imgUploadResponse } = await instance.post('/common/s3/url', {
    imgFolder: uploadFolder,
    mimeType: mimeType,
    expiration: 30,
  });

  const { preSignedUrl } = imgUploadResponse.responseData;

  console.log('uploadImageNew', imgData);

  const base64Response = await fetch(imgData);
  const blob = await base64Response.blob();

  const { data } = await instance.put(preSignedUrl, blob, { headers: { 'Content-Type': blob.type } });

  const urlWithoutQueryParams = getUrlCleamParam(preSignedUrl);

  return urlWithoutQueryParams;
};

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  let bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

export const toDataURL = async (url: string): Promise<any> => {
  // cors 이슈로 bizunit 생성 및 호출

  if (url.startsWith('data:')) {
    const found = url.match(/data:\S*;base64/g);
    return { dataUrl: url, contentType: found && found[0].slice('data:'.length, ';base64'.length * -1) };
  } else {
    // SyncTree 통해 호출

    const response = await instance.get('common/get-image-binary', {
      timeout: 120000,
      params: { imgUrl: url },
    });
    const base64Image = response.data;
    const image = `data:${response.headers['content-type']};base64,${base64Image}`;
    return { dataUrl: image, contentType: response.headers['content-type'] };

    // 직접호출

    // const response = await axios.get(url, {
    //   responseType: 'arraybuffer',
    //   timeout: 180 * 1000,
    // });
    // const contentType = response.headers['content-type'];

    // let base64ImageString = arrayBufferToBase64(response.data);

    // return { dataUrl: `data:${contentType};base64,${base64ImageString}`, contentType: contentType };
  }
};

export const translateText = async (tpCode: string, sourceLang: string, targetLang: string, text: string) => {
  const { data } = await instance.post('/products/translate', {
    API_TP_CODE: tpCode,
    beforeLanguage: translateCode[sourceLang],
    afterLanguage: translateCode[targetLang],
    translateText: text,
  });
  return data.responseData;
};

export const getOrderStatus = (id: string) => ORDER_STATUS.filter((status) => status.id === id)[0].name;

export const getRandomString = () => Math.random().toString(36).slice(2, 11);
