import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import instance from '../apis/Request';

export type marketType = {
  uploadMarketSeq: number;
  shopName: string;
};

type siteType = {
  collectionSiteSeq: number;
  collectionSiteName: string;
};

type marketListType = {
  marketList: marketType[];
  siteList: siteType[];
  apiKeyList: apiKeyType;
  setMarkets: (items: marketType[]) => void;
  setSites: (items: siteType[]) => void;
  setApiKeyList: (items: apiKeyDataType[]) => void;
  refreshApiKeyList: () => Promise<void>;
};
type apiKeyType = {
  googleInfo: apiKeyDataType;
  naverInfo: apiKeyDataType;
  aligoInfo: apiKeyDataType;
  ohooInfo: apiKeyDataType;
} | null;

type apiKeyDataType = {
  apiNo: number;
  apiVal: string;
  apiAssistVal: string | null;
  codeVal: string;
} | null;

const commonStore = create<marketListType>()(
  devtools((set, get) => ({
    // 일단 하드코딩
    marketList: [
      // { uploadMarketSeq: 1006, shopName: '11번가' },
      // ,{uploadMarketSeq: 1001, shopName: "쿠팡"}
      // ,{uploadMarketSeq: 1002, shopName: "인터파크"}
      // ,{uploadMarketSeq: 1003, shopName: "옥션"}
      // ,{uploadMarketSeq: 1004, shopName: "G마켓"}
      // ,{uploadMarketSeq: 1005, shopName: "스마트스토어"}
    ],
    siteList: [],
    apiKeyList: null,
    setMarkets: (items: marketType[]) =>
      set(() => ({
        marketList: [...items],
      })),
    setSites: (items: siteType[]) =>
      set(() => ({
        siteList: [...items],
      })),
    setApiKeyList: (items: apiKeyDataType[]) =>
      set(() => ({
        apiKeyList: {
          googleInfo: items.filter(({ apiNo }) => apiNo === 1)[0] || null,
          naverInfo: items.filter(({ apiNo }) => apiNo === 2)[0] || null,
          aligoInfo: items.filter(({ apiNo }) => apiNo === 3)[0] || null,
          ohooInfo: items.filter(({ apiNo }) => apiNo === 4)[0] || null,
        },
      })),
    refreshApiKeyList: async () => {
      const {
        data: {
          responseData: { inquiryResult },
        },
      } = await instance.get('settings/apikey');
      get().setApiKeyList(inquiryResult);
    },
  }))
);

export default commonStore;
