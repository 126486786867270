/* 언어 코드 
한국어	01
영어	02
독일어	03
이탈리아어	04
스페인어	05
일본어	06
중국어(간체)	07
중국어(번체)	08
프랑스어	09
*/
export const translateCode = {
  ko: '01',
  en: '02',
  de: '03',
  it: '04',
  es: '05',
  ja: '06',
  'zh-CN': '07',
  'zh-TW': '08',
  fr: '09',
};

export const ORDER_STATUS = [
  {
    id: 'All',
    name: '전체',
  },
  {
    id: '202',
    name: '신규주문',
  },
  {
    id: '301',
    name: '발송대기',
  },
  {
    id: '401',
    name: '배송중',
  },
  {
    id: '501',
    name: '배송완료',
  },
  {
    id: '601',
    name: '반품/교환 진행중',
  },
  {
    id: '701',
    name: '취소처리중',
  },
  {
    id: '901',
    name: '구매확정',
  },
  {
    id: 'A01',
    name: '반품완료',
  },
  {
    id: 'B01',
    name: '환불/취소완료',
  },
  {
    id: 'cancel',
    name: '취소',
  },
  {
    id: 'claim',
    name: '클레임 진행중',
  },
];

export const INVOICE_TYPE = [
  {
    id: 'invoiceAll',
    name: '전체',
  },
  {
    id: 'Y',
    name: '입력',
  },
  {
    id: 'N',
    name: '미입력',
  },
];

export const SEARCH_TYPE = [
  {
    id: '',
    name: '전체',
  },
  {
    id: 'ordererName',
    name: '주문자명',
  },
  {
    id: 'ordererPhone',
    name: '주문자연락처',
  },
  {
    id: 'orderNo',
    name: '주문번호',
  },
  {
    id: 'productName',
    name: '상품명',
  },
  {
    id: 'productCode',
    name: '상품코드',
  },
  {
    id: 'invoiceNo',
    name: '송장번호',
  },
];

export const defaultMarketCommonPolicy = {
  uploadMarketSeq: null,
  marketId: '',
  useYN: 'Y',
  marketFee: '',
  teenagerPurchaseYN: 'Y',
  categoryMatchYN: 'Y',
  marketPolicyValue: '',
};
export const defaultMarketPolicy = {
  TAX_TYPE: '01', // 면세 / 과세
  ADVERTISE: '', // 홍보문구
  AS_MESSAGE: '', // AS 안내 메세지
  AS_TEL: '', // A/S 연락처
  RETURN_FEE: 0, // (편도 반품 배송비)
  FIRST_RETURN_FEE: 0, // 초도 반품배송비 (무료 배송인 경우 사용)
  ETC_ADD_FEE: 0, // 도서지역 추가 배송비
  ORIGIN_NAME: '', // 원산지
  SELLER_TYPE: '01', //  국내 판매자 / 글로벌 판매자
  DELEVERY_FEE: 0, // 배송비 (고정배송비, 1개당 배송비, 차등 배송비)
  EXCHANGE_FEE: 0, // (교환, 왕복 반품 배송비)
  JEJU_ADD_FEE: 0, // 제주 지역 추가 배송비
  SEARCH_TAGS: [], // 검색어
  DELIVERY_DATE: 1, // 기준출고일 / 1일 설정시 다음날 출고
  DELIVERY_REPEAT_QTY: 1, // DELIVERY_REPEAT_QTY => DELIVERY_FEE_POLICY = 06(N개 수량별 배송비) 인 경우 에만 사용
  DELIVERY_CONDITIONAL_VALUE: 0, // DELIVERY_CONDITIONAL_VALUE => DELIVERY_FEE_POLICY = 03(금액조건부 무료) 인 경우 에만 사용
  DELIVER_DETAIL: [
    // (배송비 조건 array)
    {
      DELEVERY_FEE: 0, // (고정배송비, 1개당 배송비, 차등 배송비)
      CRITERION_MAX_QUANTITY: 5, // (기준 최대 수량(미만))
      CRITERION_MIN_QUANTITY: 1, // (기준 최소 수량(이상))
    },
  ],
  DELIVERY_BUNDLE_YN: 'Y', // 묶음배송 가능여부
  DELIVERY_FEE_POLICY: '01', // 배송비 정책 공통 변수 - 무료 / 고정배송비 / 수량별 차등배송비 / 1개당 배송비
  MAX_BUY_QTY: 0, // 인당 최대 구매 수량 / 제한이 없을 경우 ‘0’을 입력
  MAX_BUY_DAY: 1, // 최대 구매 수량 기간 / 제한이 없을 경우 ‘1’을 입력
  RETURN_EXCHANGE_MESSAGE: '', // 반품/교환 안내
  DELIVERY_COMPANY_SEQ: null, // 배송사Seq (마켓위즈 DLS_SEQ)
  REQUIRED_DOCUMENT_PATH: '', // 구비서류 (구비서류벤더경로 http://~) / 구비서류는 5MB이하의 파일 입력 가능 (PDF, HWP, DOC, DOCX, TXT, PNG, JPG, JPEG)
  SPMT_LOC_CODE: '',
  TKB_LOC_CODE: '',
};
