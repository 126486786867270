import { Box, Button } from '@mui/joy';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import instance from '../../../../../apis/Request';
import { phoneAutoHyphen } from '../../../../../hooks/useRegexp';
import bizInfoStore from '../../../../../stores/bizInfoStore';
import { popupStore } from '../../../../../stores/popupStore';
import MemberInfo from '../../../../frontOffice/MemberInfo';
import { successBar } from '../../../SnackBar/SnackBar';
import { useQueryClient } from '@tanstack/react-query';

interface ChangePhoneNumProps {
  userName: string;
  phoneNo: string;
}

export default function ChangePhoneNum({ userName, phoneNo }: ChangePhoneNumProps) {
  const [num, setNum] = useState('');
  const { bizInfo, setInfo } = bizInfoStore();
  const { openPopup, closePopup } = popupStore();
  const { pathname } = useLocation();

  const handleChangeNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNo = phoneAutoHyphen(e.target).value;
    setNum(newPhoneNo);
  };

  const handleClickCancel = () => {
    if (pathname.includes('selleroffice') || pathname.includes('sellerOffice')) {
      closePopup();
    } else {
      openPopup('center', '회원정보', <MemberInfo />);
    }
  };

  const queryClient = useQueryClient();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // const newNum = num.replace(/-/g, '');

    try {
      await instance.put('/settings/mobile', {
        mobileTelNo: num,
      });

      if (pathname.includes('selleroffice') || pathname.includes('sellerOffice')) {
        closePopup();
      } else {
        openPopup('center', '회원정보', <MemberInfo />);
      }
      successBar('휴대폰 번호가 변경되었습니다.');
      queryClient.invalidateQueries({ queryKey: ['userInfo'] });
      setInfo({ ...bizInfo, phoneNo: num });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="pop-contents mt-16">
      <div className="table">
        <table>
          <colgroup>
            <col style={{ width: '12.8rem' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>담당자 이름</th>
              <td>{userName}</td>
            </tr>
            <tr>
              <th>기존 휴대폰번호</th>
              <td>{phoneNo}</td>
            </tr>
            <tr>
              <th>변경할 휴대폰번호</th>
              <td className="small-pd">
                <input
                  onChange={handleChangeNum}
                  type="tel"
                  value={num}
                  required
                  maxLength={13}
                  className="input"
                  placeholder="( “-” ) 빼고 입력"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row-reverse' },
        }}>
        <Button type="submit" variant="solid" color="primary" disabled={num.length < 12}>
          저장
        </Button>
        <Button type="button" onClick={handleClickCancel} variant="outlined" color="neutral">
          취소
        </Button>
      </Box>
    </form>
  );
}
