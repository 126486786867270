import { extendTheme } from '@mui/joy/styles';

declare module '@mui/joy/ModalDialog' {
  interface ModalDialogPropsLayoutOverrides {
    panel: true;
    alert: true;
    full: true;
  }
}

declare module '@mui/joy/Button' {
  interface ButtonPropsColorOverrides {
    cancel: true;
    ok: true;
  }
}

// component 별 style override 하는 방식으로 커스터마이징
export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: 'var(--joy-palette-primary-600)', // the initial background
          solidColor: '#fff', // the initial color
          solidHoverBg: 'var(--joy-palette-primary-700)', // the :hover background
          solidActiveBg: 'var(--joy-palette-primary-800)', // the :active background
          // ...other tokens
        },
        neutral: {
          solidBg: 'var(--joy-palette-primary-700)',
          solidColor: '#fff',
          solidHoverBg: 'var(--joy-palette-primary-800)',
          solidActiveBg: 'var(--joy-palette-primary-900)',
          // ...other tokens
        },
      },
    },
  },
  components: {
    JoySelect: {
      styleOverrides: {
        root: {
          width: '16rem',
          height: '3.2rem',
          boxShadow: 'none',
          paddingLeft: '1.2rem',
          marginRight: '1rem',
          color: '#202020',
          borderColor: '#cccccc',
          fontFamily: 'Pretendard',
          fontSize: 14,
          backgroundColor: '#fff',
          '&::placeholder': {
            color: '#202020', // Placeholder 색상 변경
          },
          ':hover': {
            background: 'transparent',
          },
        },
      },
    },
    JoyOption: {
      styleOverrides: {
        root: {
          color: '#202020',
          fontFamily: 'Pretendard',
          fontSize: 14,
          backgroundColor: '#fff',
        },
      },
    },
    JoyModal: {
      styleOverrides: {
        root: {
          fontFamily: 'Pretendard',
          fontSize: 16,
        },
      },
    },
    JoyModalDialog: {
      defaultProps: { layout: 'panel' },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontFamily: 'Pretendard',
          fontSize: 16,
          backgroundColor: '#fff',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          ...(ownerState.layout === 'center' && {
            padding: '3.8rem',
            minWidth: '480px',
          }),
          ...(ownerState.layout === 'panel' && {
            top: '50%',
            right: '0',
            transform: 'translate(0,  -50%)',
            position: 'absolute',
            padding: '3.8rem',
            width: '102.4rem',
            height: '100%',
            border: '0',
            borderRadius: '0',
            outline: '0',
          }),

          ...(ownerState.layout === 'full' && {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,  -50%)',
            padding: '3.8rem',
            width: '80%',
            height: '100%',
            border: '0',
            borderRadius: '0',
            outline: '0',
          }),
          ...(ownerState.layout === 'alert' && {
            width: '28rem',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,  -50%)',
            border: '1px solid #CCCCCC',
            borderRadius: '1.6rem',
            boxShadow: '12px 12px 20px -10px rgba(0, 0, 0, 0.04), 8px 8px 40px 0px rgba(0, 0, 0, 0.16)',
            padding: 0,
          }),
        }),
      },
    },
    JoyTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontFamily: 'Pretendard',
          ...(ownerState.level === 'h4' && {
            fontSize: 17,
            textAlign: 'center',
            padding: '3rem 3rem 2rem 3rem',
            fontWeight: 600,
            color: '#202020',
          }),
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontFamily: 'Pretendard',
          fontSize: 16,
          padding: '0.8rem 1.4rem',
          ...(ownerState.color === 'cancel' && {
            width: '100%',
            height: '4.7rem',
            fontSize: '1.6rem',
            fontWeight: 700,
            color: '#666666',
            borderRight: '1px solid #eeeeee',
            borderRadius: 0,
          }),
          ...(ownerState.color === 'ok' && {
            width: '100%',
            height: '4.7rem',
            fontSize: '1.6rem',
            fontWeight: 700,
            color: '#055DB5',
            borderRadius: 0,
          }),
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        // `ownerState` contains the component props and internal state
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'lg' && {
            // borderRadius: theme.vars.radius.xs,
            '--Icon-fontSize': '3.5rem',
            '--Icon-color': '#32383E',
            '--Icon-solid': '1px',
            padding: 0,
          }),
          ...(ownerState.color === 'neutral' && {
            solidColor: '#000',
            solidBg: 'var(--joy-palette-primary-700)',
            solidHoverBg: 'var(--joy-palette-primary-800)',
            solidActiveBg: 'var(--joy-palette-primary-900)',
          }),
          ...(ownerState.variant === 'plain' && {
            solidColor: '#000',
          }),
        }),
      },
    },
  },
});
