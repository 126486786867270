import { Link, useNavigate } from 'react-router-dom';
import tabStore from '../../../stores/tabStore';
import { useLayoutEffect } from 'react';

const SellerOfficeLayoutTab = () => {
  const { items, removeItem, activeItemByIndex } = tabStore();
  const navigate = useNavigate();

  const handleClickTab = (i: number) => () => {
    activeItemByIndex(i);
  };

  const handleClickCloseTab = (i: number) => () => {
    console.log('handleClickCloseTab', i, items);
    if (items.length === 1) return;
    const nextIndex = i === 0 ? 1 : i - 1;

    removeItem(i);
    navigate(items[nextIndex].linkUrl);
    activeItemByIndex(nextIndex);
  };

  // ul 요소가 넘치는 경우에만 좌우 이동 버튼을 활성화
  const isOverflowing = () => {
    const ulElement = document.querySelector('.tab-container ul');
    return ulElement.scrollWidth > ulElement.clientWidth;
  };

  const onMoveRight = () => {
    const isOverflow = isOverflowing();

    if (isOverflow) {
      const ulElement = document.querySelector('.tab-container ul');
      ulElement.scrollLeft += 80; // 이동할 거리 조절
    }
  };

  const onMoveLeft = () => {
    const isOverflow = isOverflowing();
    console.log(isOverflow);

    if (isOverflow) {
      const ulElement = document.querySelector('.tab-container ul');
      ulElement.scrollLeft -= 80; // 이동할 거리 조절
    }
  };

  useLayoutEffect(() => {
    const isOverflow = isOverflowing();

    const scrollToTab = (index: number) => {
      const ulElement = document.querySelector('.tab-container ul');
      const tabElement = ulElement.children[index] as HTMLElement;
      if (tabElement) {
        ulElement.scrollTo({
          left: tabElement.offsetLeft - (ulElement.clientWidth - tabElement.offsetWidth) / 2,
          behavior: 'smooth',
        });
      }
    };

    if (isOverflow) {
      scrollToTab(items.length - 1);
    }
  }, [items]);

  return (
    <div className="tab-container">
      <ul>
        {items?.map((item, i) => (
          <li key={i} className={`tab ${item.active && 'active'}`}>
            <Link to={item.linkUrl} onClick={handleClickTab(i)}>
              {item.name}
            </Link>
            {/* 탭이 1개일시 삭제 부가하도록 정책 적용 */}
            {items.length > 1 && (
              <button type="button" onClick={handleClickCloseTab(i)} className="close-tab">
                닫기
              </button>
            )}
          </li>
        ))}
      </ul>
      <div className="move-wrap">
        <button onClick={onMoveLeft} className="button">
          <i className="ico-backward small" />
        </button>
        <button onClick={onMoveRight} className="button">
          <i className="ico-forward small" />
        </button>
      </div>
    </div>
  );
};

export default SellerOfficeLayoutTab;
