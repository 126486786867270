import { Link, useLocation } from 'react-router-dom';
import { LNBPropsType } from '../../../../types/LNBPropsType';
import { useEffect, useState } from 'react';
import tabStore from '../../../../stores/tabStore';

const prefPath = '/sellerOffice/managePreference';

export default function LNBPreference(props: LNBPropsType) {
  const location = useLocation();

  const [menuActive, setMenuActive] = useState<boolean>(false);
  const [depActive, setDepActive] = useState<boolean[]>([false, false]);

  const handleClickMenu = () => setMenuActive(!menuActive);

  const { items: tabItems, addItem, activeItemByUrl } = tabStore();

  const handleTabList = (e) => {
    const targetPath = e.view.location.pathname;

    const existItemIndex = tabItems.findIndex((item) => item.linkUrl === targetPath);
    if (existItemIndex === -1) {
      addItem({
        linkUrl: e.view.location.pathname,
        name: e.target.innerText,
        active: true,
      });
    }

    activeItemByUrl(e.view.location.pathname);
  };

  const handleClickDepth = (i: number) => () => {
    const dep = depActive.slice();
    dep[i] = !dep[i];
    setDepActive(dep);
  };
  useEffect(() => {
    const dep = [false, false];

    setMenuActive(location.pathname.includes(prefPath) ? true : false);
    switch (true) {
      case [`${prefPath}/bizinfo`, `${prefPath}/service`, `${prefPath}/apikey`].includes(location.pathname):
        dep[0] = true;
        break;
      case [`${prefPath}/product`, `${prefPath}/eachsite`].includes(location.pathname):
        dep[1] = true;
        break;
    }

    setDepActive(dep);
  }, [setMenuActive, location]);

  return (
    <li className={menuActive ? 'open' : ''}>
      <div className="folding-head">
        <div onClick={handleClickMenu} className="folding-btn">
          <i className="ico-setting"></i>환경설정
        </div>
      </div>
      <div className="folding-panel">
        <ul>
          <li onClick={handleClickDepth(0)} className={depActive[0] ? 'has-depth active' : 'has-depth'}>
            서비스/사업자 관리
            <i className="depth-open"></i>
            <ul>
              <li onClick={handleTabList} className="lnb-menu">
                <Link to={'managePreference/bizinfo'}>사업자 정보</Link>
              </li>
              <li onClick={handleTabList} className="lnb-menu">
                <Link to={'managePreference/service'}>서비스 추가/변경</Link>
              </li>
              <li onClick={handleTabList} className="lnb-menu">
                <Link to={'managePreference/apikey'}>API 연동키 설정</Link>
              </li>
            </ul>
          </li>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to={'managePreference/marketinfo'}>마켓 정보/계정 관리</Link>
          </li>
          <li onClick={handleTabList} className="lnb-menu">
            <Link to={'managePreference/exchange'}>환율설정</Link>
          </li>
        </ul>
      </div>
    </li>
  );
}
