import { SyntheticEvent } from 'react';
import bizInfoStore from '../../../stores/bizInfoStore';

import LNBPolicy from './LNB/LNBPolicy';
import LNBPreference from './LNB/LNBPreference';
import LNBProduct from './LNB/LNBProduct';
import LNBSelect from './LNB/LNBSelect';

import sampleImg from '../../../images/common/mongmong.png';
import LNBOrder from './LNB/LNBOrder';

type Props = {
  toggleMenu: () => void;
  menuOpen: boolean;
};

/**
 * 각 메뉴 최하위 뎁스를 클릭해야 링크 이동
 */

const SellerOfficeLayoutLNB = ({ menuOpen, toggleMenu }: Props) => {
  const { bizInfo } = bizInfoStore();
  const handleToggleMenu = () => toggleMenu();

  const addDefaultLogo = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    console.log('addDefaultLogo', e.currentTarget.src);
    e.currentTarget.src = sampleImg;
  };

  return (
    <div className="lnb">
      <div className={`lnb-inner ${!menuOpen ? 'fold' : ''}`}>
        <div className="img-box w-40 obf-contain">
          <img src={bizInfo.spmImg} onError={addDefaultLogo} alt={bizInfo.spmName} />
        </div>
        <p className="logo-name">{bizInfo.spmName}</p>
        <button onClick={handleToggleMenu} className="close-lnb">
          닫기
        </button>

        <ul className={`folding-list ${!menuOpen && 'folded'}`}>
          {/* <LNBMyMenu menuOpen={menuOpen} /> */}
          <LNBSelect menuOpen={menuOpen} />
          <LNBProduct menuOpen={menuOpen} />
          <LNBPolicy menuOpen={menuOpen} />
          <LNBOrder menuOpen={menuOpen} />
          {/* <LNBInquiry menuOpen={menuOpen} /> */}
          <LNBPreference menuOpen={menuOpen} />
        </ul>
      </div>
    </div>
  );
};

export default SellerOfficeLayoutLNB;
