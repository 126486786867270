import { useState } from 'react';

interface ChatProps {
  classname: string;
  text?: string;
}

export default function ChatButton({ classname, text }: ChatProps) {
  const [widgetVisible, setWidgetVisible] = useState(false);

  const openWidget = () => {
    document.getElementById('fc_frame').style.visibility = 'visible';
    window['fcWidget'].open();
    setTimeout(() => {
      initEvents();
    }, 400);
  };

  // 위젯 visible 핸들링
  const initEvents = () => {
    if (window['fcWidget']) {
      window['fcWidget'].on('widget:opened', (resp) => {
        setWidgetVisible(true);
      });
      window['fcWidget'].on('widget:closed', (resp) => {
        setWidgetVisible(false);
      });
    } else {
      console.log('fcWidget is not available');
    }
  };

  return (
    <div className={classname} onClick={openWidget} style={{ cursor: 'pointer', visibility: 'visible' }}>
      {text && text}
    </div>
  );
}
