import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type tabItemType = {
  linkUrl: string;
  name: string;
  active: boolean;
};

type tabItemsType = {
  items: tabItemType[];
  addItem: (tabItem: tabItemType) => void;
  removeItem: (index: number) => void;
  activeItemByIndex: (index: number) => void;
  activeItemByUrl: (url: string) => void;
};

// 대시보드 페이지 생성 전까지 상품수집이 메인
const initTab = {
  linkUrl: '/sellerOffice/manageCollection/siteCollect',
  name: '사이트 상품수집',
  active: true,
};

const tabStore = create<tabItemsType>()(
  devtools((set) => ({
    items: [initTab],

    // 탭 아이템 추가
    // addItem: (tabItem: tabItemType) =>
    //   set((state) => {
    //     const addItems = state.items.slice();
    //     addItems.push(tabItem);
    //     return {
    //       items: addItems,
    //     };
    //   }),
    addItem: (tabItem: tabItemType) =>
      set((state) => ({
        items: [...state.items, tabItem],
      })),

    // 탭 아이템 제거
    // removeItem: (index: number) =>
    //   set((state) => {
    //     const addItems = state.items.slice();
    //     // console.log('addItems', addItems)
    //     // console.log('remove index', index)
    //     if (index === 0) return;
    //     if (typeof addItems[index] !== 'undefined') {
    //       addItems.splice(index, 1);

    //       return {
    //         items: addItems,
    //       };
    //     }
    //   }),

    removeItem: (index: number) =>
      set((state) => {
        // index가 0일 때 삭제 시도를 막음
        // if (index === 0) return;

        return {
          items: state.items.filter((_, idx) => index !== idx),
        };
      }),

    // 현재 선택된 탭 아이템
    activeItemByIndex: (index: number) =>
      set((state) => {
        const returnItems = state.items.map((item, i) => {
          item.active = index === i ? true : false;
          return item;
        });
        return {
          items: returnItems,
        };
      }),

    activeItemByUrl: (url: string) =>
      set((state) => {
        const returnItems = state.items.map((item) => {
          item.active = item.linkUrl === url;
          return item;
        });
        return {
          items: returnItems,
        };
      }),
  }))
);

export default tabStore;
