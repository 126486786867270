import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import instance from '../apis/Request';

export type solutionType = {
  sollutionNo: number;
  solutionName: string;
  solutionDesc: string;
  solutionPrice: number;
  collectionSiteCount: number;
  uploadMarketCount: number;
  uploadAccountCount: number;
  productionSaveCount: number;
};

type userSolutionType = {
  solutionProductNo: number | null;
  useStartDate: string | null;
  useEndDate: string | null;
  productSaveUseCount: number | null;
  uploadMarketAccountUseCount: number | null;
  solutionProductName: string | null;
  solutionProductAmount: number | null;
  period: string | null;
  periodCode: string | null;
};
type chargeDaysType = {
  dayNo: number;
  dayName: string;
  dayCode: string;
  days: string;
};

type solutionListType = {
  solutionList: solutionType[];
  chargeDays: chargeDaysType[];
  userSolution: userSolutionType | null;
  userPreviousSolution: userSolutionType | null;
  setSolutionList: (items: solutionType[]) => void;
  setChargeDays: (items: chargeDaysType[]) => void;
  setUserSolution: (userSol: userSolutionType) => void;
  setUserPreviousSolution: (userSol: userSolutionType) => void;
  unsetSolution: () => void;
  refreshUserSolution: () => Promise<void>;
};

const solutionStore = create<solutionListType>()(
  devtools((set) => ({
    solutionList: [],
    chargeDays: [],
    userSolution: null,
    userPreviousSolution: null,
    setSolutionList: (items: solutionType[]) =>
      set(() => ({
        solutionList: [...items],
      })),
    setChargeDays: (items: chargeDaysType[]) =>
      set(() => ({
        chargeDays: [...items],
      })),
    setUserSolution: (userSol: userSolutionType) => {
      set(() => ({
        userSolution: userSol,
      }));
    },
    setUserPreviousSolution: (userSol: userSolutionType) => {
      set(() => ({
        userPreviousSolution: userSol,
      }));
    },
    unsetSolution: () =>
      set(() => {
        return {
          solutionList: [],
          userSolution: null,
        };
      }),
    refreshUserSolution: async () => {
      const { data: userSolutionData } = await instance.get('/solution/me');
      set(() => {
        if (userSolutionData?.responseData.inquiryResult.length > 0) {
          return {
            userSolution: userSolutionData?.responseData.inquiryResult[0],
          };
        }
        if (userSolutionData?.responseData.previousSolution.length > 0) {
          return {
            userPreviousSolution: userSolutionData?.responseData.previousSolution[0],
          };
        }
      });
    },
  }))
);

export default solutionStore;
