'use client';

import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

interface ErrorBoundaryContextProps {
  children: React.ReactNode;
}
const logError = (error: Error, info: { componentStack: string }) => {
  console.error('에러 정보를 로깅해요!', error, info);
};

const ErrorFallback = ({ resetErrorBoundary, error }: FallbackProps) => {
  const { status, message } = error;

  const onClickHandler = () => {
    return resetErrorBoundary();
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-gray-100">
      <div className="mb-4 text-6xl font-bold text-gray-500">{status}</div>
      <button
        className="rounded bg-yellow-400 px-4 py-2 text-white hover:bg-yellow-300 focus:border-blue-700 focus:outline-none focus:ring focus:ring-blue-200"
        onClick={onClickHandler}>
        {message}
      </button>
    </div>
  );
};

const ErrorBoundaryContext = ({ children }: ErrorBoundaryContextProps) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary onReset={reset} onError={logError} FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryContext;
