import { Box, Button } from '@mui/joy';
import { useQueries } from '@tanstack/react-query';
import instance from '../../apis/Request';
import { popupStore } from '../../stores/popupStore';
import ChangePhoneNum from '../Util/PopUp/PopUpList/SO/ChangePhoneNum';
import { alertStore } from './../../stores/alertStore';
import WithdrawMember from './WithdrawMember';

export default function MemberInfo() {
  const { openPopup, closePopup } = popupStore();

  const [{ data: userSolutionInfo, isLoading: userSolutionLoading }, { data: userInfo, isLoading: userInfoLoading }] =
    useQueries({
      queries: [
        {
          queryKey: ['userSolution'],
          queryFn: async () => {
            const response = await instance.get('/solution/me');
            return response.data.responseData.inquiryResult;
          },
        },
        {
          queryKey: ['userInfo'],
          queryFn: async () => {
            const response = await instance.get('/user/sessioninfo');
            return response.data.responseData;
          },
          staleTime: Infinity,
        },
      ],
    });

  const handleClickCancel = () => closePopup();

  const handleChangePhoneNo = () => {
    openPopup(
      'center',
      '담당자 휴대폰번호 변경',
      <ChangePhoneNum userName={userInfo.userName} phoneNo={userInfo.mobileTelNo} />,
      '저장',
      '취소',
      false
    );
  };

  const { openAlert } = alertStore();
  const handleWithdraw = () => {
    if (userSolutionInfo.length > 0) {
      openAlert(
        '현재 솔루션을 이용중입니다. 이용중인 솔루션의 기간이 종료되거나, 환불한 후 탈퇴할 수 있습니다.',
        '확인'
      );
    } else {
      openPopup('center', '회원 탈퇴 시 유의사항 안내', <WithdrawMember />);
    }
  };

  if (!userSolutionLoading && !userInfoLoading && userInfo)
    return (
      <>
        <div className="table mt-16">
          <table>
            <colgroup>
              <col style={{ width: '12.8rem' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <tbody>
              <tr>
                <th>담당자 이름</th>
                <td className="small-pd">{userInfo.userName}</td>
              </tr>
              <tr>
                <th>가입 채널</th>
                <td className="small-pd">{userInfo.socialName}</td>
              </tr>
              <tr>
                <th>이메일</th>
                <td className="small-pd">{userInfo.userEmail}</td>
              </tr>
              <tr>
                <th>휴대폰 번호</th>
                <td className="small-pd flex align-center space-between">
                  <p>{userInfo.mobileTelNo}</p>
                  <button type="button" className="button small line" onClick={handleChangePhoneNo}>
                    정보변경
                  </button>
                </td>
              </tr>
              <tr>
                <th>가입일시</th>
                <td className="small-pd">{userInfo.regDate.split(' ')[0]}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <Box
          sx={{
            mt: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Button onClick={handleWithdraw} type="button" variant="outlined" color="neutral">
            회원탈퇴
          </Button>

          <Button onClick={handleClickCancel} type="submit" variant="solid" color="primary">
            확인
          </Button>
        </Box>
      </>
    );
}
